import { AxiosError } from 'axios';
import type { DefaultApi, LoadCurvesGet200Response } from '../api-client';
import { ForbiddenAccessError, InvalidResponseError } from '../errors';
import type { User } from '../user';
import type { LoadCurveApi, LoadCurves } from './load-curve.interface';

class LoadCurvesApiFacade implements LoadCurveApi {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private apiClient: DefaultApi,
    private currentUser: Promise<User>,
    // eslint-disable-next-line no-empty-function
  ) {}

  async getLoadCurves(withDefault?: boolean, projectId?: number): Promise<LoadCurves> {
    try {
      const user = await this.currentUser;
      const response = await this.apiClient.loadCurvesGet(user.id, withDefault, projectId);
      if (response.data === undefined) {
        throw new InvalidResponseError();
      }
      const rawLoadCurves = response.data as LoadCurvesGet200Response;

      if (rawLoadCurves === undefined) {
        throw new InvalidResponseError();
      }

      return rawLoadCurves;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 403) {
        throw new ForbiddenAccessError();
      } else {
        throw new InvalidResponseError();
      }
    }
  }
}

export { LoadCurvesApiFacade };
