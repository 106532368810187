import { h, type ComponentPublicInstance, type FunctionalComponent } from 'vue';

const aliases: { [componentName: string]: any } = {
  // Declare the icons here
};

// Types duplicated from Vuetify (they are not exported)

type JSXComponent<Props = any> =
  | {
      new (): ComponentPublicInstance<Props>;
    }
  | FunctionalComponent<Props>;
type IconValue = string | (string | [path: string, opacity: number])[] | JSXComponent;

const customIcons = {
  component: (props: { icon?: IconValue; [_: string]: any }) =>
    h(aliases[props.icon as unknown as string]),
};

// eslint-disable-next-line import/prefer-default-export
export { customIcons };
