import { AxiosError } from 'axios';
import {
  geoJSONCoordinatesToPolygonGeometry,
  polygonGeometryToGeoJSONCoordinates,
} from '@/util/geography';
import { type DefaultApi, type StudyStudyIdGeojsonGet200ResponseOneOf } from '../api-client';
import type {
  ThermalZoneApi,
  ThermalZone,
  ThermalZoneProperties,
} from './thermal-zone.interface';
import { ForbiddenAccessError, InvalidInputError, InvalidResponseError } from '../errors';

const rawThermalZoneProperties: Array<string> = [
  'name',
  'building_id',
  'building_name',
  'altitude',
  'height',
  'mean_floor_height',
  'floor_count',
  'gross_floor_area',
  'usable_floor_area_ratio',
  'usable_floor_area',
  'usage_zone_code',
  'performance',
  'year',
  'infiltration_rate',
  'ventilation_system',
  'ExteriorFloor_U_value',
  'ExteriorFloor_inertia',
  'ExteriorFloor_insulation',
  'ExteriorRoof_U_value',
  'ExteriorRoof_inertia',
  'ExteriorRoof_insulation',
  'ExteriorRoof_window_type',
  'ExteriorRoof_window_U_value',
  'ExteriorRoof_window_solar_absorption',
  'ExteriorRoof_window_transmission_factor',
  'ExteriorRoof_window_share',
  'ExteriorWall_U_value',
  'ExteriorWall_inertia',
  'ExteriorWall_insulation',
  'ExteriorWall_window_type',
  'ExteriorWall_window_U_value',
  'ExteriorWall_window_share',
  'ExteriorWall_window_solar_absorption',
  'ExteriorWall_window_transmission_factor',
  'blind_control_mode',
  'closed_blind_ratio',
  'closing_deltaT',
  'open_blind_ratio',
  'temperature_blind_closed',
  'is_initialized',
  'systems_0',
  'systems_1',
  'systems_2',
  'systems_3',
  'scenario_air_change_rate_set_point',
  'scenario_cooling_set_point',
  'scenario_electric_load',
  'scenario_heating_set_point',
  'scenario_internal_gain_metabolism',
  'scenario_water_flow',
  'load_curve_cooling',
  'load_curve_dhw',
  'load_curve_electricity',
  'load_curve_heating',
  'calc_mode',
];

function validateId(id: string): void {
  const regex = /^[a-zA-Z]_\d+$/;
  if (!regex.test(id)) {
    throw new InvalidResponseError();
  }
}

const scenarioProperties: Record<string, string> = {
  scenario_heating_set_point: 'heatingScenarioId',
  scenario_cooling_set_point: 'coolingScenarioId',
  scenario_air_change_rate_set_point: 'airChangeRateScenarioId',
  scenario_internal_gain_metabolism: 'internalGainMetabolismScenarioId',
  scenario_water_flow: 'domesticHotWaterScenarioId',
  scenario_electric_load: 'specificElectricityScenarioId',
};

const loadCurveProperties: Record<string, string> = {
  load_curve_heating: 'heatingLoadCurveId',
  load_curve_cooling: 'coolingLoadCurveId',
  load_curve_dhw: 'domesticHotWaterLoadCurveId',
  load_curve_electricity: 'specificElectricityLoadCurveId',
};

const profileProperties: Record<string, string> = {
  ...scenarioProperties,
  ...loadCurveProperties,
};

function convertRawPropertiesToFacade(properties: any): ThermalZoneProperties {
  validateId(properties.building_id);
  validateId(properties.id);
  const validProperties: any = {};
  rawThermalZoneProperties.forEach((property) => {
    if (properties[property] !== undefined) {
      validProperties[property] = properties[property];
    } else {
      console.log(property);
      throw new InvalidResponseError();
    }

    if (property === 'ExteriorWall_window_share') {
      const exteriorWallWindowShare = properties.ExteriorWall_window_share;
      delete validProperties.ExteriorWall_window_share;

      validProperties.ExteriorWall_window_share_N = exteriorWallWindowShare.N;
      validProperties.ExteriorWall_window_share_NE = exteriorWallWindowShare.NE;
      validProperties.ExteriorWall_window_share_NW = exteriorWallWindowShare.NW;
      validProperties.ExteriorWall_window_share_S = exteriorWallWindowShare.S;
      validProperties.ExteriorWall_window_share_SE = exteriorWallWindowShare.SE;
      validProperties.ExteriorWall_window_share_SW = exteriorWallWindowShare.SW;
      validProperties.ExteriorWall_window_share_E = exteriorWallWindowShare.E;
      validProperties.ExteriorWall_window_share_W = exteriorWallWindowShare.W;
    }

    if (properties.performance === 'Indéfinie') {
      validProperties.performance = 'UNDEFINED';
    }
    if (property in profileProperties) {
      const profileProperty: string = profileProperties[property];
      validProperties[profileProperty] = properties[property] || undefined;

      delete validProperties[property];
    }

    // Check profiles consistency
    if (
      properties.scenario_heating_set_point !== null &&
      properties.load_curve_heating !== null
    ) {
      throw new InvalidResponseError();
    }
    if (
      properties.scenario_cooling_set_point !== null &&
      properties.load_curve_cooling !== null
    ) {
      throw new InvalidResponseError();
    }
    if (properties.scenario_water_flow !== null && properties.load_curve_dhw !== null) {
      throw new InvalidResponseError();
    }
    if (
      properties.scenario_electric_load !== null &&
      properties.load_curve_electricity !== null
    ) {
      throw new InvalidResponseError();
    }

    // Set profile mode properties from calc_mode value and/or loadcurve property value
    if (property === 'calc_mode') {
      if (properties.calc_mode === 'dynamic thermal simulation') {
        validProperties.heatingAndCoolingProfileMode = 'SCENARIO';
        validProperties.domesticHotWaterProfileMode = 'SCENARIO';
        validProperties.specificElectricityProfileMode = 'SCENARIO';

        // LoadCurve properties should be undefined
        Object.entries(loadCurveProperties).forEach(([loadCurveProperty]) => {
          if (validProperties[loadCurveProperties[loadCurveProperty]] !== undefined) {
            throw new InvalidResponseError();
          }
        });
      } else {
        validProperties.heatingAndCoolingProfileMode = 'LOAD_CURVE';
        // Heating and cooling scenarios should be undefined
        if (
          validProperties.heatingScenarioId !== undefined ||
          validProperties.coolingScenarioId !== undefined ||
          validProperties.airChangeRateScenarioId !== undefined ||
          validProperties.internalGainMetabolismScenarioId !== undefined
        ) {
          throw new InvalidResponseError();
        }
        if (properties.load_curve_dhw !== null) {
          validProperties.domesticHotWaterProfileMode = 'LOAD_CURVE';
        }
        if (properties.load_curve_electricity !== null) {
          validProperties.specificElectricityProfileMode = 'LOAD_CURVE';
        }
      }
      delete validProperties[property];
    }
  });

  return validProperties;
}

function convertFacadePropertiesToRaw(
  thermalZoneId: string,
  properties: ThermalZoneProperties,
): object {
  const validProperties: any = { ...properties };

  validProperties.id = thermalZoneId;

  validProperties.ExteriorWall_window_share = {
    N: properties.ExteriorWall_window_share_N,
    NE: properties.ExteriorWall_window_share_NE,
    NW: properties.ExteriorWall_window_share_NW,
    S: properties.ExteriorWall_window_share_S,
    SE: properties.ExteriorWall_window_share_SE,
    SW: properties.ExteriorWall_window_share_SW,
    E: properties.ExteriorWall_window_share_E,
    W: properties.ExteriorWall_window_share_W,
  };

  delete validProperties.ExteriorWall_window_share_N;
  delete validProperties.ExteriorWall_window_share_NE;
  delete validProperties.ExteriorWall_window_share_NW;
  delete validProperties.ExteriorWall_window_share_S;
  delete validProperties.ExteriorWall_window_share_SE;
  delete validProperties.ExteriorWall_window_share_SW;
  delete validProperties.ExteriorWall_window_share_E;
  delete validProperties.ExteriorWall_window_share_W;

  if (validProperties.performance === 'UNDEFINED') validProperties.performance = 'Indéfinie';

  // We're not having initialization anymore state in our app thanks to our dynamic defaults selectors
  validProperties.is_initialized = true;

  // TODO Model these properties in ThermalZoneProperties
  validProperties.systems_0 = null;
  validProperties.systems_1 = null;
  validProperties.systems_2 = null;
  validProperties.systems_3 = validProperties.ventilation_system;

  // Check inconsistent mode config
  if (
    validProperties.heatingAndCoolingProfileMode === 'SCENARIO' &&
    (validProperties.domesticHotWaterProfileMode === 'LOAD_CURVE' ||
      validProperties.specificElectricityProfileMode === 'LOAD_CURVE')
  ) {
    throw new InvalidInputError();
  }
  if (validProperties.heatingAndCoolingProfileMode === 'SCENARIO') {
    validProperties.calc_mode = 'dynamic thermal simulation';
    validProperties.heatingLoadCurveId = null;
    validProperties.coolingLoadCurveId = null;
    validProperties.domesticHotWaterLoadCurveId = null;
    validProperties.specificElectricityLoadCurveId = null;
  } else {
    validProperties.calc_mode = 'load_curve';
    validProperties.heatingScenarioId = null;
    validProperties.coolingScenarioId = null;
    if (validProperties.domesticHotWaterProfileMode === 'SCENARIO') {
      validProperties.domesticHotWaterLoadCurveId = null;
    } else {
      validProperties.domesticHotWaterScenarioId = null;
    }
    if (validProperties.specificElectricityProfileMode === 'SCENARIO') {
      validProperties.specificElectricityLoadCurveId = null;
    } else {
      validProperties.specificElectricityScenarioId = null;
    }
  }
  delete validProperties.heatingAndCoolingProfileMode;
  delete validProperties.domesticHotWaterProfileMode;
  delete validProperties.specificElectricityProfileMode;

  // Set profiles properties with legacy names
  Object.entries(profileProperties).forEach(([legacyProperty, property]) => {
    const profileProperty: string = validProperties[property];
    validProperties[legacyProperty] = profileProperty || null;
    delete validProperties[property];
  });
  return validProperties;
}

class ThermalZoneApiFacade implements ThermalZoneApi {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private apiClient: DefaultApi) {}

  async getStudyThermalZones(studyId: number): Promise<Array<ThermalZone>> {
    try {
      const response = await this.apiClient.studyStudyIdGeojsonGet(studyId, true);

      if (response.data.status === false) {
        throw new InvalidInputError();
      }

      const rawThermalZones = (response.data as StudyStudyIdGeojsonGet200ResponseOneOf).data;

      if (rawThermalZones === undefined) {
        throw new InvalidResponseError();
      }

      const features = rawThermalZones.thermal_zone.features.map((feature) => ({
        id: feature.properties.id,
        properties: convertRawPropertiesToFacade(feature.properties),
        geometry: geoJSONCoordinatesToPolygonGeometry(
          feature.geometry.coordinates as Array<Array<[number, number]>>,
        ),
      }));
      return features;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 403) {
        throw new ForbiddenAccessError();
      } else {
        throw new InvalidResponseError();
      }
    }
  }

  async persistStudyThermalZones(studyId: number, thermalZones: ThermalZone[]): Promise<void> {
    const response = await this.apiClient.studyStudyIdUpdatePut(studyId, {
      geojson_zone: {
        type: 'FeatureCollection',
        crs: {
          properties: {
            name: 'epsg:4326',
          },
          type: 'name',
        },
        features: thermalZones.map((thermalZone) => {
          return {
            type: 'Feature',
            properties: convertFacadePropertiesToRaw(thermalZone.id, thermalZone.properties),
            geometry: {
              type: 'Polygon',
              coordinates: polygonGeometryToGeoJSONCoordinates(thermalZone.geometry),
            },
          };
        }),
      },
    });

    if (response.data.status === false) {
      throw new InvalidInputError();
    }
  }
}

const _testSubjects = {
  convertFacadePropertiesToRaw,
  convertRawPropertiesToFacade,
  validateId,
};

export { _testSubjects, ThermalZoneApiFacade };
