/* eslint-disable no-useless-constructor, no-empty-function */

import { AxiosError } from 'axios';
import type { DefaultApi, ScenarioGetAllGet200ResponseOneOf } from '../api-client';
import { ForbiddenAccessError, InvalidInputError, InvalidResponseError } from '../errors';
import type { User } from '../user';
import type { ScenarioApi, Scenarios } from './scenario.interface';

class ScenarioApiFacade implements ScenarioApi {
  constructor(
    private apiClient: DefaultApi,
    private currentUser: Promise<User>,
  ) {}

  async getScenarios(withDefault: boolean, projectId?: number): Promise<Scenarios> {
    try {
      const user = await this.currentUser;
      const response = await this.apiClient.scenarioGetAllGet(user.id, withDefault, projectId);
      if (response.data.status === false) {
        throw new InvalidInputError();
      }

      const rawScenarios = (response.data as ScenarioGetAllGet200ResponseOneOf).data;

      if (rawScenarios === undefined) {
        throw new InvalidResponseError();
      }
      return rawScenarios;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 403) {
        throw new ForbiddenAccessError();
      } else {
        throw new InvalidResponseError();
      }
    }
  }
}

export { ScenarioApiFacade };
