/* eslint-disable class-methods-use-this */
import { toRing } from '@/components/soon-shared/util';
import type { ProfileModes, ThermalZone, ThermalZoneApi } from './thermal-zone.interface';

export class ThermalZoneApiMock implements ThermalZoneApi {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getStudyThermalZones(studyId: number): Promise<ThermalZone[]> {
    // TODO: generate realistic data and take studyId into account (simulate not found and
    // forbidden)

    const heightsAndAreas = {
      floor_count: 2,
      mean_floor_height: 2.7,
      height: 2 * 2.7,
      gross_floor_area: 1000,
      usable_floor_area_ratio: 0.7,
      usable_floor_area: 700,
    };

    const defaultProfiles = {
      heatingAndCoolingProfileMode: 'SCENARIO' as ProfileModes,
      heatingScenarioId: undefined,
      heatingLoadCurveId: undefined,
      coolingScenarioId: undefined,
      coolingLoadCurveId: undefined,
      airChangeRateScenarioId: undefined,
      internalGainMetabolismScenarioId: undefined,

      domesticHotWaterProfileMode: 'SCENARIO' as ProfileModes,
      domesticHotWaterScenarioId: undefined,
      domesticHotWaterLoadCurveId: undefined,

      specificElectricityProfileMode: 'SCENARIO' as ProfileModes,
      specificElectricityScenarioId: undefined,
      specificElectricityLoadCurveId: undefined,
    };

    return [
      {
        id: 'z_1',
        properties: {
          ExteriorFloor_U_value: 0.69,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.69,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.77,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.2,
          ExteriorWall_window_share_NE: 0.2,
          ExteriorWall_window_share_NW: 0.2,
          ExteriorWall_window_share_S: 0.2,
          ExteriorWall_window_share_SE: 0.2,
          ExteriorWall_window_share_SW: 0.2,
          ExteriorWall_window_share_E: 0.2,
          ExteriorWall_window_share_W: 0.2,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 97.3,
          building_id: 'b_1',
          building_name: 'Résidence Perronet',
          infiltration_rate: 0.18,
          name: 'Restaurants',
          performance: '1984-1996',
          usage_zone_code: 'B43',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1994.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
          heatingScenarioId: 'heating_set_point B43',
          coolingScenarioId: 'cooling_set_point B43',
          airChangeRateScenarioId: 'air_change_rate_set_point B43',
          internalGainMetabolismScenarioId: 'internal_gain_metabolism B43',
          domesticHotWaterScenarioId: 'water_flow B43',
          specificElectricityScenarioId: 'electric_load B43',
        },
        geometry: [
          toRing([
            {
              lat: 48.84079155,
              lng: 2.58479837,
            },
            {
              lat: 48.8408733,
              lng: 2.58477319,
            },
            {
              lat: 48.84174025,
              lng: 2.58450741,
            },
            {
              lat: 48.84173618,
              lng: 2.58437119,
            },
            {
              lat: 48.84160862,
              lng: 2.58441445,
            },
            {
              lat: 48.84160399,
              lng: 2.58437633,
            },
            {
              lat: 48.84080443,
              lng: 2.58462114,
            },
            {
              lat: 48.84081543,
              lng: 2.58468373,
            },
            {
              lat: 48.84079952,
              lng: 2.58476152,
            },
            {
              lat: 48.84079155,
              lng: 2.58479837,
            },
          ]),
        ],
      },
      {
        id: 'z_21',
        properties: {
          ExteriorFloor_U_value: 0.69,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.69,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.77,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.2,
          ExteriorWall_window_share_NE: 0.2,
          ExteriorWall_window_share_NW: 0.2,
          ExteriorWall_window_share_S: 0.2,
          ExteriorWall_window_share_SE: 0.2,
          ExteriorWall_window_share_SW: 0.2,
          ExteriorWall_window_share_E: 0.2,
          ExteriorWall_window_share_W: 0.2,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 101,
          building_id: 'b_1',
          building_name: 'Résidence Perronet',
          infiltration_rate: 0.18,
          name: 'Logements étudiants',
          performance: '1984-1996',
          usage_zone_code: 'B25',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1994.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
          heatingScenarioId: 'heating_set_point B25',
          coolingScenarioId: 'cooling_set_point B25',
          airChangeRateScenarioId: 'air_change_rate_set_point B25',
          internalGainMetabolismScenarioId: 'internal_gain_metabolism B25',
          domesticHotWaterScenarioId: 'water_flow B25',
          specificElectricityScenarioId: 'electric_load B25',
        },
        geometry: [
          toRing([
            {
              lat: 48.84079155,
              lng: 2.58479837,
            },
            {
              lat: 48.8408733,
              lng: 2.58477319,
            },
            {
              lat: 48.84174025,
              lng: 2.58450741,
            },
            {
              lat: 48.84173618,
              lng: 2.58437119,
            },
            {
              lat: 48.84160862,
              lng: 2.58441445,
            },
            {
              lat: 48.84160399,
              lng: 2.58437633,
            },
            {
              lat: 48.84080443,
              lng: 2.58462114,
            },
            {
              lat: 48.84081543,
              lng: 2.58468373,
            },
            {
              lat: 48.84079952,
              lng: 2.58476152,
            },
            {
              lat: 48.84079155,
              lng: 2.58479837,
            },
          ]),
        ],
      },
      {
        id: 'z_2',
        properties: {
          ExteriorFloor_U_value: 0.69,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.69,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.77,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.2,
          ExteriorWall_window_share_NE: 0.2,
          ExteriorWall_window_share_NW: 0.2,
          ExteriorWall_window_share_S: 0.2,
          ExteriorWall_window_share_SE: 0.2,
          ExteriorWall_window_share_SW: 0.2,
          ExteriorWall_window_share_E: 0.2,
          ExteriorWall_window_share_W: 0.2,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 97.1,
          building_id: 'b_2',
          building_name: 'Résidence Figuier',
          infiltration_rate: 0.19,
          name: 'Logements étudiants',
          performance: '1984-1996',
          usage_zone_code: 'B25',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1990.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84097831,
              lng: 2.58600678,
            },
            {
              lat: 48.84099449,
              lng: 2.58600529,
            },
            {
              lat: 48.84101697,
              lng: 2.58600375,
            },
            {
              lat: 48.84101702,
              lng: 2.58601873,
            },
            {
              lat: 48.84105927,
              lng: 2.58601431,
            },
            {
              lat: 48.84106022,
              lng: 2.58602793,
            },
            {
              lat: 48.84111147,
              lng: 2.58602343,
            },
            {
              lat: 48.84111141,
              lng: 2.58600708,
            },
            {
              lat: 48.84113299,
              lng: 2.58600555,
            },
            {
              lat: 48.84118874,
              lng: 2.58600374,
            },
            {
              lat: 48.84120672,
              lng: 2.58600224,
            },
            {
              lat: 48.84120767,
              lng: 2.58601722,
            },
            {
              lat: 48.84126252,
              lng: 2.58601406,
            },
            {
              lat: 48.84126246,
              lng: 2.58599635,
            },
            {
              lat: 48.84130023,
              lng: 2.58599468,
            },
            {
              lat: 48.84134249,
              lng: 2.58599299,
            },
            {
              lat: 48.84133654,
              lng: 2.58583226,
            },
            {
              lat: 48.84127,
              lng: 2.58583687,
            },
            {
              lat: 48.84126213,
              lng: 2.58563937,
            },
            {
              lat: 48.84123695,
              lng: 2.5856423,
            },
            {
              lat: 48.84123593,
              lng: 2.58560552,
            },
            {
              lat: 48.84123108,
              lng: 2.58550337,
            },
            {
              lat: 48.84109082,
              lng: 2.58551539,
            },
            {
              lat: 48.84109376,
              lng: 2.58558485,
            },
            {
              lat: 48.84110365,
              lng: 2.58558477,
            },
            {
              lat: 48.84111804,
              lng: 2.58558329,
            },
            {
              lat: 48.84112299,
              lng: 2.58571542,
            },
            {
              lat: 48.8411259,
              lng: 2.58577534,
            },
            {
              lat: 48.84114298,
              lng: 2.58577521,
            },
            {
              lat: 48.84114505,
              lng: 2.58585285,
            },
            {
              lat: 48.84110639,
              lng: 2.58585452,
            },
            {
              lat: 48.84110539,
              lng: 2.58582728,
            },
            {
              lat: 48.84106313,
              lng: 2.58582898,
            },
            {
              lat: 48.84106233,
              lng: 2.5858576,
            },
            {
              lat: 48.84101467,
              lng: 2.5858607,
            },
            {
              lat: 48.84097241,
              lng: 2.58586104,
            },
            {
              lat: 48.84097831,
              lng: 2.58600678,
            },
          ]),
        ],
      },
      {
        id: 'z_3',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.2,
          ExteriorWall_window_share_NE: 0.2,
          ExteriorWall_window_share_NW: 0.2,
          ExteriorWall_window_share_S: 0.2,
          ExteriorWall_window_share_SE: 0.2,
          ExteriorWall_window_share_SW: 0.2,
          ExteriorWall_window_share_E: 0.2,
          ExteriorWall_window_share_W: 0.2,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 97.0,
          building_id: 'b_3',
          building_name: 'Résidence Meunier',
          infiltration_rate: 0.19,
          name: 'Logements étudiants',
          performance: 'UNDEFINED',
          usage_zone_code: 'B25',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1996.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84134249,
              lng: 2.58599299,
            },
            {
              lat: 48.84139194,
              lng: 2.58598714,
            },
            {
              lat: 48.84139198,
              lng: 2.58599941,
            },
            {
              lat: 48.84154302,
              lng: 2.58598594,
            },
            {
              lat: 48.84154208,
              lng: 2.58597233,
            },
            {
              lat: 48.84156725,
              lng: 2.5859694,
            },
            {
              lat: 48.84156531,
              lng: 2.5859299,
            },
            {
              lat: 48.84159588,
              lng: 2.58592694,
            },
            {
              lat: 48.84159691,
              lng: 2.58596508,
            },
            {
              lat: 48.84169492,
              lng: 2.58595885,
            },
            {
              lat: 48.84168119,
              lng: 2.58562923,
            },
            {
              lat: 48.8416632,
              lng: 2.58562937,
            },
            {
              lat: 48.84165824,
              lng: 2.58549589,
            },
            {
              lat: 48.84167533,
              lng: 2.58549575,
            },
            {
              lat: 48.84167081,
              lng: 2.5852301,
            },
            {
              lat: 48.84164106,
              lng: 2.58520853,
            },
            {
              lat: 48.84163482,
              lng: 2.58522629,
            },
            {
              lat: 48.84160778,
              lng: 2.58520743,
            },
            {
              lat: 48.84160154,
              lng: 2.5852252,
            },
            {
              lat: 48.84157449,
              lng: 2.58520497,
            },
            {
              lat: 48.84157982,
              lng: 2.58518586,
            },
            {
              lat: 48.84155368,
              lng: 2.58516699,
            },
            {
              lat: 48.84155901,
              lng: 2.58514787,
            },
            {
              lat: 48.84142736,
              lng: 2.58505082,
            },
            {
              lat: 48.84142469,
              lng: 2.58505902,
            },
            {
              lat: 48.84140125,
              lng: 2.58504013,
            },
            {
              lat: 48.8413941,
              lng: 2.58505381,
            },
            {
              lat: 48.8413572,
              lng: 2.58504593,
            },
            {
              lat: 48.84135031,
              lng: 2.58513318,
            },
            {
              lat: 48.84134492,
              lng: 2.58513595,
            },
            {
              lat: 48.84134224,
              lng: 2.58514006,
            },
            {
              lat: 48.84133957,
              lng: 2.58514962,
            },
            {
              lat: 48.84133961,
              lng: 2.58516188,
            },
            {
              lat: 48.84134503,
              lng: 2.58516865,
            },
            {
              lat: 48.84133812,
              lng: 2.58525046,
            },
            {
              lat: 48.84143642,
              lng: 2.5853287,
            },
            {
              lat: 48.84144266,
              lng: 2.58531094,
            },
            {
              lat: 48.841448,
              lng: 2.58529591,
            },
            {
              lat: 48.84148857,
              lng: 2.5853242,
            },
            {
              lat: 48.84152204,
              lng: 2.58538116,
            },
            {
              lat: 48.84152414,
              lng: 2.58546698,
            },
            {
              lat: 48.84151155,
              lng: 2.58546708,
            },
            {
              lat: 48.8414972,
              lng: 2.58547945,
            },
            {
              lat: 48.84148737,
              lng: 2.58549724,
            },
            {
              lat: 48.84148113,
              lng: 2.58551364,
            },
            {
              lat: 48.84147673,
              lng: 2.58554093,
            },
            {
              lat: 48.84147587,
              lng: 2.58555184,
            },
            {
              lat: 48.84147592,
              lng: 2.58556682,
            },
            {
              lat: 48.84147687,
              lng: 2.58558044,
            },
            {
              lat: 48.84148052,
              lng: 2.58559812,
            },
            {
              lat: 48.84148325,
              lng: 2.58560492,
            },
            {
              lat: 48.84148596,
              lng: 2.58561034,
            },
            {
              lat: 48.84148868,
              lng: 2.58561713,
            },
            {
              lat: 48.84149411,
              lng: 2.58562663,
            },
            {
              lat: 48.84151579,
              lng: 2.58565371,
            },
            {
              lat: 48.84152928,
              lng: 2.5856536,
            },
            {
              lat: 48.84153225,
              lng: 2.5857326,
            },
            {
              lat: 48.8415099,
              lng: 2.58577093,
            },
            {
              lat: 48.84145866,
              lng: 2.58577679,
            },
            {
              lat: 48.84145769,
              lng: 2.58575772,
            },
            {
              lat: 48.84133361,
              lng: 2.58576552,
            },
            {
              lat: 48.84133654,
              lng: 2.58583226,
            },
            {
              lat: 48.84134249,
              lng: 2.58599299,
            },
          ]),
        ],
      },
      {
        id: 'z_4',
        properties: {
          ExteriorFloor_U_value: 0.69,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.69,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.77,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.2,
          ExteriorWall_window_share_NE: 0.2,
          ExteriorWall_window_share_NW: 0.2,
          ExteriorWall_window_share_S: 0.2,
          ExteriorWall_window_share_SE: 0.2,
          ExteriorWall_window_share_SW: 0.2,
          ExteriorWall_window_share_E: 0.2,
          ExteriorWall_window_share_W: 0.2,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 97.5,
          building_id: 'b_4',
          building_name: 'Résidence Copernic',
          infiltration_rate: 0.18,
          name: 'Restaurants',
          performance: '1984-1996',
          usage_zone_code: 'B43',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1991.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84239632,
              lng: 2.58411795,
            },
            {
              lat: 48.84236572,
              lng: 2.58411138,
            },
            {
              lat: 48.84235225,
              lng: 2.58411693,
            },
            {
              lat: 48.84229386,
              lng: 2.58413648,
            },
            {
              lat: 48.84218606,
              lng: 2.58417276,
            },
            {
              lat: 48.84215193,
              lng: 2.5841853,
            },
            {
              lat: 48.84217404,
              lng: 2.58433636,
            },
            {
              lat: 48.84224051,
              lng: 2.58431403,
            },
            {
              lat: 48.84224515,
              lng: 2.58435351,
            },
            {
              lat: 48.84230265,
              lng: 2.58433806,
            },
            {
              lat: 48.8423017,
              lng: 2.58432444,
            },
            {
              lat: 48.84234752,
              lng: 2.58431045,
            },
            {
              lat: 48.84234846,
              lng: 2.58432134,
            },
            {
              lat: 48.84238889,
              lng: 2.58430876,
            },
            {
              lat: 48.84241677,
              lng: 2.5843099,
            },
            {
              lat: 48.84241853,
              lng: 2.58429762,
            },
            {
              lat: 48.84246262,
              lng: 2.58430408,
            },
            {
              lat: 48.84246092,
              lng: 2.58433271,
            },
            {
              lat: 48.8425383,
              lng: 2.58434299,
            },
            {
              lat: 48.84253825,
              lng: 2.58433073,
            },
            {
              lat: 48.84260934,
              lng: 2.58434243,
            },
            {
              lat: 48.8426006,
              lng: 2.58415719,
            },
            {
              lat: 48.84239632,
              lng: 2.58411795,
            },
          ]),
        ],
      },
      {
        id: 'z_22',
        properties: {
          ExteriorFloor_U_value: 0.69,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.69,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.77,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.2,
          ExteriorWall_window_share_NE: 0.2,
          ExteriorWall_window_share_NW: 0.2,
          ExteriorWall_window_share_S: 0.2,
          ExteriorWall_window_share_SE: 0.2,
          ExteriorWall_window_share_SW: 0.2,
          ExteriorWall_window_share_E: 0.2,
          ExteriorWall_window_share_W: 0.2,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 100.4,
          building_id: 'b_4',
          building_name: 'Résidence Copernic',
          infiltration_rate: 0.18,
          name: 'Logements étudiants',
          performance: '1984-1996',
          usage_zone_code: 'B25',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1991.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84239632,
              lng: 2.58411795,
            },
            {
              lat: 48.84236572,
              lng: 2.58411138,
            },
            {
              lat: 48.84235225,
              lng: 2.58411693,
            },
            {
              lat: 48.84229386,
              lng: 2.58413648,
            },
            {
              lat: 48.84218606,
              lng: 2.58417276,
            },
            {
              lat: 48.84215193,
              lng: 2.5841853,
            },
            {
              lat: 48.84217404,
              lng: 2.58433636,
            },
            {
              lat: 48.84224051,
              lng: 2.58431403,
            },
            {
              lat: 48.84224515,
              lng: 2.58435351,
            },
            {
              lat: 48.84230265,
              lng: 2.58433806,
            },
            {
              lat: 48.8423017,
              lng: 2.58432444,
            },
            {
              lat: 48.84234752,
              lng: 2.58431045,
            },
            {
              lat: 48.84234846,
              lng: 2.58432134,
            },
            {
              lat: 48.84238889,
              lng: 2.58430876,
            },
            {
              lat: 48.84241677,
              lng: 2.5843099,
            },
            {
              lat: 48.84241853,
              lng: 2.58429762,
            },
            {
              lat: 48.84246262,
              lng: 2.58430408,
            },
            {
              lat: 48.84246092,
              lng: 2.58433271,
            },
            {
              lat: 48.8425383,
              lng: 2.58434299,
            },
            {
              lat: 48.84253825,
              lng: 2.58433073,
            },
            {
              lat: 48.84260934,
              lng: 2.58434243,
            },
            {
              lat: 48.8426006,
              lng: 2.58415719,
            },
            {
              lat: 48.84239632,
              lng: 2.58411795,
            },
          ]),
        ],
      },
      {
        id: 'z_5',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.2,
          ExteriorWall_window_share_NE: 0.2,
          ExteriorWall_window_share_NW: 0.2,
          ExteriorWall_window_share_S: 0.2,
          ExteriorWall_window_share_SE: 0.2,
          ExteriorWall_window_share_SW: 0.2,
          ExteriorWall_window_share_E: 0.2,
          ExteriorWall_window_share_W: 0.2,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 97.3,
          building_id: 'b_5',
          building_name: 'Résidence Gagarine',
          infiltration_rate: 0.18,
          name: 'Logements étudiants',
          performance: 'UNDEFINED',
          usage_zone_code: 'B25',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: null,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84077174,
              lng: 2.58608744,
            },
            {
              lat: 48.84074542,
              lng: 2.58549906,
            },
            {
              lat: 48.8409534,
              lng: 2.58530802,
            },
            {
              lat: 48.84114883,
              lng: 2.58512797,
            },
            {
              lat: 48.84113826,
              lng: 2.58493186,
            },
            {
              lat: 48.84110061,
              lng: 2.58496758,
            },
            {
              lat: 48.84063626,
              lng: 2.58540319,
            },
            {
              lat: 48.84066745,
              lng: 2.58609781,
            },
            {
              lat: 48.84077174,
              lng: 2.58608744,
            },
          ]),
        ],
      },
      {
        id: 'z_6',
        properties: {
          ExteriorFloor_U_value: 0.69,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.69,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.77,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.2,
          ExteriorWall_window_share_NE: 0.2,
          ExteriorWall_window_share_NW: 0.2,
          ExteriorWall_window_share_S: 0.2,
          ExteriorWall_window_share_SE: 0.2,
          ExteriorWall_window_share_SW: 0.2,
          ExteriorWall_window_share_E: 0.2,
          ExteriorWall_window_share_W: 0.2,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 95.2,
          building_id: 'b_6',
          building_name: 'Résidence Montesquieu et Condorcet',
          infiltration_rate: 0.18,
          name: 'Logements étudiants',
          performance: '1984-1996',
          usage_zone_code: 'B25',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1993.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84226946,
              lng: 2.5859175,
            },
            {
              lat: 48.84207615,
              lng: 2.58593402,
            },
            {
              lat: 48.84203918,
              lng: 2.5859057,
            },
            {
              lat: 48.84200756,
              lng: 2.58586235,
            },
            {
              lat: 48.842091,
              lng: 2.58580719,
            },
            {
              lat: 48.84212869,
              lng: 2.585781,
            },
            {
              lat: 48.84213494,
              lng: 2.58576869,
            },
            {
              lat: 48.84213929,
              lng: 2.58572778,
            },
            {
              lat: 48.84219491,
              lng: 2.58568646,
            },
            {
              lat: 48.84222214,
              lng: 2.58575982,
            },
            {
              lat: 48.84224097,
              lng: 2.58574196,
            },
            {
              lat: 48.84223459,
              lng: 2.58571748,
            },
            {
              lat: 48.84227673,
              lng: 2.58568036,
            },
            {
              lat: 48.84228402,
              lng: 2.58570755,
            },
            {
              lat: 48.84251328,
              lng: 2.58568665,
            },
            {
              lat: 48.84254548,
              lng: 2.58563462,
            },
            {
              lat: 48.8425348,
              lng: 2.58540989,
            },
            {
              lat: 48.84256537,
              lng: 2.58540556,
            },
            {
              lat: 48.84256148,
              lng: 2.58532111,
            },
            {
              lat: 48.84268105,
              lng: 2.58531062,
            },
            {
              lat: 48.84268403,
              lng: 2.58539099,
            },
            {
              lat: 48.84266968,
              lng: 2.585402,
            },
            {
              lat: 48.84267166,
              lng: 2.58545512,
            },
            {
              lat: 48.84268696,
              lng: 2.58545909,
            },
            {
              lat: 48.84270072,
              lng: 2.58579553,
            },
            {
              lat: 48.8426999,
              lng: 2.5858187,
            },
            {
              lat: 48.84269547,
              lng: 2.58583917,
            },
            {
              lat: 48.84267758,
              lng: 2.5858652,
            },
            {
              lat: 48.84265336,
              lng: 2.58588311,
            },
            {
              lat: 48.84260211,
              lng: 2.5858876,
            },
            {
              lat: 48.8425921,
              lng: 2.58585226,
            },
            {
              lat: 48.84257415,
              lng: 2.5858633,
            },
            {
              lat: 48.84258054,
              lng: 2.5858905,
            },
            {
              lat: 48.84246455,
              lng: 2.5858996,
            },
            {
              lat: 48.84246359,
              lng: 2.58588053,
            },
            {
              lat: 48.84240424,
              lng: 2.58588236,
            },
            {
              lat: 48.84240791,
              lng: 2.5859055,
            },
            {
              lat: 48.84229553,
              lng: 2.58591593,
            },
            {
              lat: 48.84226946,
              lng: 2.5859175,
            },
          ]),
        ],
      },
      {
        id: 'z_7',
        properties: {
          ExteriorFloor_U_value: 0.42,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.32,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.46,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.42,
          ExteriorWall_window_share_NE: 0.42,
          ExteriorWall_window_share_NW: 0.42,
          ExteriorWall_window_share_S: 0.42,
          ExteriorWall_window_share_SE: 0.42,
          ExteriorWall_window_share_SW: 0.42,
          ExteriorWall_window_share_E: 0.42,
          ExteriorWall_window_share_W: 0.42,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 94.2,
          building_id: 'b_8',
          building_name: 'Bâtiment Coriolis',
          infiltration_rate: 0.31,
          name: 'z_7',
          performance: '1997-2008',
          usage_zone_code: 'B14',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 2005.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84083594,
              lng: 2.58984469,
            },
            {
              lat: 48.84084873,
              lng: 2.59016477,
            },
            {
              lat: 48.84106071,
              lng: 2.59035113,
            },
            {
              lat: 48.84129174,
              lng: 2.59032206,
            },
            {
              lat: 48.84133038,
              lng: 2.59031222,
            },
            {
              lat: 48.84139062,
              lng: 2.5903063,
            },
            {
              lat: 48.84138468,
              lng: 2.59014829,
            },
            {
              lat: 48.84133074,
              lng: 2.59015281,
            },
            {
              lat: 48.84132504,
              lng: 2.59006429,
            },
            {
              lat: 48.8412783,
              lng: 2.59007147,
            },
            {
              lat: 48.84126541,
              lng: 2.58972413,
            },
            {
              lat: 48.84083594,
              lng: 2.58984469,
            },
          ]),
        ],
      },
      {
        id: 'z_8',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.45,
          ExteriorWall_window_share_NE: 0.45,
          ExteriorWall_window_share_NW: 0.45,
          ExteriorWall_window_share_S: 0.45,
          ExteriorWall_window_share_SE: 0.45,
          ExteriorWall_window_share_SW: 0.45,
          ExteriorWall_window_share_E: 0.45,
          ExteriorWall_window_share_W: 0.45,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 96.5,
          building_id: 'b_10',
          building_name: 'ENSG-Géomatique',
          infiltration_rate: 0.18,
          name: 'z_8',
          performance: 'UNDEFINED',
          usage_zone_code: 'B15',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: null,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.8413136,
              lng: 2.58830539,
            },
            {
              lat: 48.84133421,
              lng: 2.58880526,
            },
            {
              lat: 48.84146008,
              lng: 2.58879473,
            },
            {
              lat: 48.84138465,
              lng: 2.58700091,
            },
            {
              lat: 48.84125698,
              lng: 2.58701146,
            },
            {
              lat: 48.84126481,
              lng: 2.58719669,
            },
            {
              lat: 48.84112097,
              lng: 2.58721146,
            },
            {
              lat: 48.84111403,
              lng: 2.58702621,
            },
            {
              lat: 48.84098727,
              lng: 2.58703948,
            },
            {
              lat: 48.8409942,
              lng: 2.58722336,
            },
            {
              lat: 48.84084316,
              lng: 2.58723818,
            },
            {
              lat: 48.84083533,
              lng: 2.58705295,
            },
            {
              lat: 48.84071215,
              lng: 2.58706346,
            },
            {
              lat: 48.8407912,
              lng: 2.58886268,
            },
            {
              lat: 48.84091436,
              lng: 2.58884809,
            },
            {
              lat: 48.84089285,
              lng: 2.58834686,
            },
            {
              lat: 48.84089185,
              lng: 2.58831689,
            },
            {
              lat: 48.84088991,
              lng: 2.58827739,
            },
            {
              lat: 48.84104005,
              lng: 2.58826258,
            },
            {
              lat: 48.84104198,
              lng: 2.58830072,
            },
            {
              lat: 48.84104299,
              lng: 2.58833341,
            },
            {
              lat: 48.84106359,
              lng: 2.58883192,
            },
            {
              lat: 48.84119127,
              lng: 2.58882138,
            },
            {
              lat: 48.84116795,
              lng: 2.5883188,
            },
            {
              lat: 48.84116695,
              lng: 2.58828883,
            },
            {
              lat: 48.84116502,
              lng: 2.58825206,
            },
            {
              lat: 48.84131066,
              lng: 2.58823456,
            },
            {
              lat: 48.84131169,
              lng: 2.58827406,
            },
            {
              lat: 48.8413136,
              lng: 2.58830539,
            },
          ]),
        ],
      },
      {
        id: 'z_9',
        properties: {
          ExteriorFloor_U_value: 0.52,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.43,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.08,
          ExteriorRoof_window_transmission_factor: 0.789,
          ExteriorRoof_window_type: 'single_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.53,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.45,
          ExteriorWall_window_share_NE: 0.45,
          ExteriorWall_window_share_NW: 0.45,
          ExteriorWall_window_share_S: 0.45,
          ExteriorWall_window_share_SE: 0.45,
          ExteriorWall_window_share_SW: 0.45,
          ExteriorWall_window_share_E: 0.45,
          ExteriorWall_window_share_W: 0.45,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.08,
          ExteriorWall_window_transmission_factor: 0.789,
          ExteriorWall_window_type: 'single_glazing',
          altitude: 90.3,
          building_id: 'b_11',
          building_name: 'ESIEE Paris',
          infiltration_rate: 0.52,
          name: 'z_9',
          performance: '1984-1996',
          usage_zone_code: 'B15',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1989.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.83975153,
              lng: 2.58313764,
            },
            {
              lat: 48.83974782,
              lng: 2.58310769,
            },
            {
              lat: 48.83961847,
              lng: 2.58315096,
            },
            {
              lat: 48.8396286,
              lng: 2.58321901,
            },
            {
              lat: 48.83968942,
              lng: 2.58363816,
            },
            {
              lat: 48.83971366,
              lng: 2.58362843,
            },
            {
              lat: 48.83972287,
              lng: 2.5836883,
            },
            {
              lat: 48.83951449,
              lng: 2.58376626,
            },
            {
              lat: 48.83950799,
              lng: 2.58370773,
            },
            {
              lat: 48.83953225,
              lng: 2.58370072,
            },
            {
              lat: 48.83947426,
              lng: 2.58331834,
            },
            {
              lat: 48.83945768,
              lng: 2.58320811,
            },
            {
              lat: 48.83932921,
              lng: 2.58324729,
            },
            {
              lat: 48.83935314,
              lng: 2.58340378,
            },
            {
              lat: 48.83940468,
              lng: 2.58374125,
            },
            {
              lat: 48.83943072,
              lng: 2.58373015,
            },
            {
              lat: 48.83943905,
              lng: 2.58379548,
            },
            {
              lat: 48.83917944,
              lng: 2.58388475,
            },
            {
              lat: 48.83892164,
              lng: 2.583974,
            },
            {
              lat: 48.8388929,
              lng: 2.58398377,
            },
            {
              lat: 48.83870876,
              lng: 2.58404927,
            },
            {
              lat: 48.8387152,
              lng: 2.58409009,
            },
            {
              lat: 48.83871612,
              lng: 2.5840969,
            },
            {
              lat: 48.83874666,
              lng: 2.58408576,
            },
            {
              lat: 48.83878349,
              lng: 2.58407456,
            },
            {
              lat: 48.83878723,
              lng: 2.58411541,
            },
            {
              lat: 48.83876298,
              lng: 2.58412377,
            },
            {
              lat: 48.8388487,
              lng: 2.58472393,
            },
            {
              lat: 48.83994283,
              lng: 2.58435688,
            },
            {
              lat: 48.83995964,
              lng: 2.584275,
            },
            {
              lat: 48.83999169,
              lng: 2.58418209,
            },
            {
              lat: 48.84003366,
              lng: 2.58409729,
            },
            {
              lat: 48.84007659,
              lng: 2.58402882,
            },
            {
              lat: 48.84013125,
              lng: 2.5839698,
            },
            {
              lat: 48.84019224,
              lng: 2.58392299,
            },
            {
              lat: 48.8402515,
              lng: 2.5838939,
            },
            {
              lat: 48.8403171,
              lng: 2.58387975,
            },
            {
              lat: 48.84038818,
              lng: 2.58388736,
            },
            {
              lat: 48.84044579,
              lng: 2.58390325,
            },
            {
              lat: 48.84051965,
              lng: 2.58393536,
            },
            {
              lat: 48.84058007,
              lng: 2.58398256,
            },
            {
              lat: 48.84062162,
              lng: 2.58403401,
            },
            {
              lat: 48.84066417,
              lng: 2.58411542,
            },
            {
              lat: 48.84116091,
              lng: 2.5839425,
            },
            {
              lat: 48.8410761,
              lng: 2.58334777,
            },
            {
              lat: 48.84105185,
              lng: 2.58335886,
            },
            {
              lat: 48.84104266,
              lng: 2.58330035,
            },
            {
              lat: 48.84110015,
              lng: 2.58328354,
            },
            {
              lat: 48.84109552,
              lng: 2.5832427,
            },
            {
              lat: 48.84096795,
              lng: 2.5832846,
            },
            {
              lat: 48.84096232,
              lng: 2.58321515,
            },
            {
              lat: 48.84098388,
              lng: 2.58320953,
            },
            {
              lat: 48.84091289,
              lng: 2.58271006,
            },
            {
              lat: 48.84078443,
              lng: 2.58275333,
            },
            {
              lat: 48.8408509,
              lng: 2.58324329,
            },
            {
              lat: 48.84087784,
              lng: 2.58323354,
            },
            {
              lat: 48.84088802,
              lng: 2.58331657,
            },
            {
              lat: 48.84068768,
              lng: 2.58337676,
            },
            {
              lat: 48.84067572,
              lng: 2.58330056,
            },
            {
              lat: 48.84069999,
              lng: 2.58329628,
            },
            {
              lat: 48.84063353,
              lng: 2.58280767,
            },
            {
              lat: 48.84050328,
              lng: 2.58285095,
            },
            {
              lat: 48.84057152,
              lng: 2.58333409,
            },
            {
              lat: 48.84059486,
              lng: 2.58332437,
            },
            {
              lat: 48.84060594,
              lng: 2.58340603,
            },
            {
              lat: 48.84040114,
              lng: 2.58347851,
            },
            {
              lat: 48.84039189,
              lng: 2.58340365,
            },
            {
              lat: 48.84041525,
              lng: 2.58339801,
            },
            {
              lat: 48.84034073,
              lng: 2.58291629,
            },
            {
              lat: 48.84021494,
              lng: 2.58295136,
            },
            {
              lat: 48.84028228,
              lng: 2.5834345,
            },
            {
              lat: 48.84031103,
              lng: 2.5834261,
            },
            {
              lat: 48.84032477,
              lng: 2.5834982,
            },
            {
              lat: 48.84008312,
              lng: 2.58357915,
            },
            {
              lat: 48.84007387,
              lng: 2.58350565,
            },
            {
              lat: 48.84009903,
              lng: 2.58349728,
            },
            {
              lat: 48.84002811,
              lng: 2.58301825,
            },
            {
              lat: 48.83990053,
              lng: 2.58305606,
            },
            {
              lat: 48.83996697,
              lng: 2.58354057,
            },
            {
              lat: 48.83999303,
              lng: 2.58353218,
            },
            {
              lat: 48.84000496,
              lng: 2.58360294,
            },
            {
              lat: 48.83980286,
              lng: 2.5836754,
            },
            {
              lat: 48.83979454,
              lng: 2.58361007,
            },
            {
              lat: 48.8398197,
              lng: 2.58360306,
            },
            {
              lat: 48.83975153,
              lng: 2.58313764,
            },
          ]),
        ],
      },
      {
        id: 'z_10',
        properties: {
          ExteriorFloor_U_value: 0.24,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.22,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.33,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.45,
          ExteriorWall_window_share_NE: 0.45,
          ExteriorWall_window_share_NW: 0.45,
          ExteriorWall_window_share_S: 0.45,
          ExteriorWall_window_share_SE: 0.45,
          ExteriorWall_window_share_SW: 0.45,
          ExteriorWall_window_share_E: 0.45,
          ExteriorWall_window_share_W: 0.45,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 91.4,
          building_id: 'b_14',
          building_name: "École d'Urbanisme de Paris",
          infiltration_rate: 0.2,
          name: 'z_10',
          performance: '2015-2018',
          usage_zone_code: 'B15',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 2015.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            { lat: 48.84249401, lng: 2.58661606 },
            { lat: 48.84249815, lng: 2.58625223 },
            { lat: 48.84272661, lng: 2.58625995 },
            { lat: 48.84271771, lng: 2.58706801 },
            { lat: 48.84271717, lng: 2.58717293 },
            { lat: 48.84271251, lng: 2.58764713 },
            { lat: 48.84268791, lng: 2.58807516 },
            { lat: 48.84266293, lng: 2.58865716 },
            { lat: 48.84262564, lng: 2.58905941 },
            { lat: 48.84240512, lng: 2.58900801 },
            { lat: 48.84242613, lng: 2.58883889 },
            { lat: 48.8424706, lng: 2.58817362 },
            { lat: 48.84249086, lng: 2.58778922 },
            { lat: 48.84249133, lng: 2.58714202 },
          ]),
          toRing([
            { lat: 48.84253222, lng: 2.58804505 },
            { lat: 48.8425229, lng: 2.58821272 },
            { lat: 48.84261737, lng: 2.58822287 },
            { lat: 48.84262668, lng: 2.58805521 },
            { lat: 48.84253222, lng: 2.58804505 },
          ]),
          toRing([
            { lat: 48.84255861, lng: 2.58709243 },
            { lat: 48.84255642, lng: 2.58723824 },
            { lat: 48.84264995, lng: 2.58724022 },
            { lat: 48.84265214, lng: 2.58709169 },
            { lat: 48.84255861, lng: 2.58709243 },
          ]),
        ],
      },
      {
        id: 'z_11',
        properties: {
          ExteriorFloor_U_value: 0.42,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.32,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.46,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.45,
          ExteriorWall_window_share_NE: 0.45,
          ExteriorWall_window_share_NW: 0.45,
          ExteriorWall_window_share_S: 0.45,
          ExteriorWall_window_share_SE: 0.45,
          ExteriorWall_window_share_SW: 0.45,
          ExteriorWall_window_share_E: 0.45,
          ExteriorWall_window_share_W: 0.45,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 97.4,
          building_id: 'b_15',
          building_name: 'ESIPE',
          infiltration_rate: 0.31,
          name: 'z_11',
          performance: '1997-2008',
          usage_zone_code: 'B15',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 2005.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.8393178,
              lng: 2.58878575,
            },
            {
              lat: 48.83927733,
              lng: 2.58826288,
            },
            {
              lat: 48.83945378,
              lng: 2.58831463,
            },
            {
              lat: 48.83946428,
              lng: 2.58822735,
            },
            {
              lat: 48.83944267,
              lng: 2.5882207,
            },
            {
              lat: 48.83939496,
              lng: 2.58820746,
            },
            {
              lat: 48.83944578,
              lng: 2.58781739,
            },
            {
              lat: 48.83949799,
              lng: 2.58783333,
            },
            {
              lat: 48.8395169,
              lng: 2.58783863,
            },
            {
              lat: 48.83953096,
              lng: 2.58774315,
            },
            {
              lat: 48.83951115,
              lng: 2.58773649,
            },
            {
              lat: 48.83945983,
              lng: 2.58772055,
            },
            {
              lat: 48.83951249,
              lng: 2.58734137,
            },
            {
              lat: 48.8395647,
              lng: 2.5873573,
            },
            {
              lat: 48.83958361,
              lng: 2.58736397,
            },
            {
              lat: 48.83959678,
              lng: 2.58726985,
            },
            {
              lat: 48.83957697,
              lng: 2.58726456,
            },
            {
              lat: 48.83952476,
              lng: 2.58724862,
            },
            {
              lat: 48.83957738,
              lng: 2.58686263,
            },
            {
              lat: 48.8396296,
              lng: 2.58687857,
            },
            {
              lat: 48.83964851,
              lng: 2.58688523,
            },
            {
              lat: 48.83966166,
              lng: 2.58678703,
            },
            {
              lat: 48.83964366,
              lng: 2.58678172,
            },
            {
              lat: 48.83958965,
              lng: 2.58676716,
            },
            {
              lat: 48.83964315,
              lng: 2.58637435,
            },
            {
              lat: 48.83969897,
              lng: 2.58639162,
            },
            {
              lat: 48.83971607,
              lng: 2.58639693,
            },
            {
              lat: 48.83972743,
              lng: 2.58630011,
            },
            {
              lat: 48.83916833,
              lng: 2.58612606,
            },
            {
              lat: 48.83914904,
              lng: 2.58626927,
            },
            {
              lat: 48.83911484,
              lng: 2.58626001,
            },
            {
              lat: 48.83911628,
              lng: 2.58615781,
            },
            {
              lat: 48.83910629,
              lng: 2.58612928,
            },
            {
              lat: 48.8390856,
              lng: 2.58612672,
            },
            {
              lat: 48.83907576,
              lng: 2.58614179,
            },
            {
              lat: 48.83907576,
              lng: 2.58614451,
            },
            {
              lat: 48.83907322,
              lng: 2.58618949,
            },
            {
              lat: 48.83906874,
              lng: 2.58619225,
            },
            {
              lat: 48.83906687,
              lng: 2.58617319,
            },
            {
              lat: 48.83906587,
              lng: 2.58614323,
            },
            {
              lat: 48.83892661,
              lng: 2.58618521,
            },
            {
              lat: 48.83892867,
              lng: 2.58626012,
            },
            {
              lat: 48.83894755,
              lng: 2.58625725,
            },
            {
              lat: 48.83898975,
              lng: 2.58675693,
            },
            {
              lat: 48.83899538,
              lng: 2.58682501,
            },
            {
              lat: 48.83901035,
              lng: 2.58699383,
            },
            {
              lat: 48.83899867,
              lng: 2.58699665,
            },
            {
              lat: 48.83897803,
              lng: 2.58700908,
            },
            {
              lat: 48.83896551,
              lng: 2.58703097,
            },
            {
              lat: 48.83895299,
              lng: 2.58705015,
            },
            {
              lat: 48.83894679,
              lng: 2.58708017,
            },
            {
              lat: 48.83894153,
              lng: 2.58711836,
            },
            {
              lat: 48.83894436,
              lng: 2.58715785,
            },
            {
              lat: 48.83895163,
              lng: 2.58717959,
            },
            {
              lat: 48.83896704,
              lng: 2.58721217,
            },
            {
              lat: 48.83899051,
              lng: 2.58723787,
            },
            {
              lat: 48.83900402,
              lng: 2.58724457,
            },
            {
              lat: 48.83903372,
              lng: 2.58724979,
            },
            {
              lat: 48.83903465,
              lng: 2.58726068,
            },
            {
              lat: 48.83904028,
              lng: 2.58732876,
            },
            {
              lat: 48.83907229,
              lng: 2.58774269,
            },
            {
              lat: 48.83907792,
              lng: 2.58781076,
            },
            {
              lat: 48.83908356,
              lng: 2.5878802,
            },
            {
              lat: 48.83911271,
              lng: 2.5882492,
            },
            {
              lat: 48.83911743,
              lng: 2.58831456,
            },
            {
              lat: 48.83912398,
              lng: 2.58838944,
            },
            {
              lat: 48.83916255,
              lng: 2.58888234,
            },
            {
              lat: 48.83920658,
              lng: 2.58887109,
            },
            {
              lat: 48.83922725,
              lng: 2.58886684,
            },
            {
              lat: 48.83927744,
              lng: 2.58881604,
            },
            {
              lat: 48.83930704,
              lng: 2.58879401,
            },
            {
              lat: 48.8393178,
              lng: 2.58878575,
            },
          ]),
        ],
      },
      {
        id: 'z_12',
        properties: {
          ExteriorFloor_U_value: 0.24,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.22,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.33,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.45,
          ExteriorWall_window_share_NE: 0.45,
          ExteriorWall_window_share_NW: 0.45,
          ExteriorWall_window_share_S: 0.45,
          ExteriorWall_window_share_SE: 0.45,
          ExteriorWall_window_share_SW: 0.45,
          ExteriorWall_window_share_E: 0.45,
          ExteriorWall_window_share_W: 0.45,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 91.4,
          building_id: 'b_16',
          building_name: 'Bâtiment Bienvenüe',
          infiltration_rate: 0.31,
          name: 'z_12',
          performance: '2015-2018',
          usage_zone_code: 'B15',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 2015.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84224272,
              lng: 2.58885668,
            },
            {
              lat: 48.84206018,
              lng: 2.58886357,
            },
            {
              lat: 48.8420195,
              lng: 2.58775753,
            },
            {
              lat: 48.84199699,
              lng: 2.58722768,
            },
            {
              lat: 48.84196853,
              lng: 2.58653983,
            },
            {
              lat: 48.84195595,
              lng: 2.58628242,
            },
            {
              lat: 48.84214475,
              lng: 2.58626457,
            },
            {
              lat: 48.8421692,
              lng: 2.58683255,
            },
            {
              lat: 48.84219839,
              lng: 2.58747543,
            },
            {
              lat: 48.84221008,
              lng: 2.58747397,
            },
            {
              lat: 48.84221683,
              lng: 2.58708288,
            },
            {
              lat: 48.84223254,
              lng: 2.58668489,
            },
            {
              lat: 48.84224543,
              lng: 2.58625151,
            },
            {
              lat: 48.84239109,
              lng: 2.58624218,
            },
            {
              lat: 48.84241221,
              lng: 2.58662897,
            },
            {
              lat: 48.84247335,
              lng: 2.58662439,
            },
            {
              lat: 48.84249401,
              lng: 2.58661606,
            },
            {
              lat: 48.84249133,
              lng: 2.58714202,
            },
            {
              lat: 48.84249086,
              lng: 2.58778922,
            },
            {
              lat: 48.8424706,
              lng: 2.58817362,
            },
            {
              lat: 48.84242613,
              lng: 2.58883889,
            },
            {
              lat: 48.84240512,
              lng: 2.58900801,
            },
            {
              lat: 48.84262564,
              lng: 2.58905941,
            },
            {
              lat: 48.84262303,
              lng: 2.58908395,
            },
            {
              lat: 48.84257699,
              lng: 2.58955848,
            },
            {
              lat: 48.84235738,
              lng: 2.58950979,
            },
            {
              lat: 48.84234687,
              lng: 2.58959163,
            },
            {
              lat: 48.84227315,
              lng: 2.58959902,
            },
            {
              lat: 48.84224272,
              lng: 2.58885668,
            },
          ]),
        ],
      },
      {
        id: 'z_14',
        properties: {
          ExteriorFloor_U_value: 0.87,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 0.42,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.08,
          ExteriorRoof_window_transmission_factor: 0.789,
          ExteriorRoof_window_type: 'single_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 0.53,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.42,
          ExteriorWall_window_share_NE: 0.42,
          ExteriorWall_window_share_NW: 0.42,
          ExteriorWall_window_share_S: 0.42,
          ExteriorWall_window_share_SE: 0.42,
          ExteriorWall_window_share_SW: 0.42,
          ExteriorWall_window_share_E: 0.42,
          ExteriorWall_window_share_W: 0.42,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.08,
          ExteriorWall_window_transmission_factor: 0.789,
          ExteriorWall_window_type: 'single_glazing',
          altitude: 96.6,
          building_id: 'b_18',
          building_name: 'Restaurant Universitaire Crous Club',
          infiltration_rate: 0.43,
          name: 'Restaurant Universitaire Crous Club',
          performance: '1984-1996',
          usage_zone_code: 'B43',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1993.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.842091,
              lng: 2.58580719,
            },
            {
              lat: 48.84212869,
              lng: 2.585781,
            },
            {
              lat: 48.84213494,
              lng: 2.58576869,
            },
            {
              lat: 48.84213929,
              lng: 2.58572778,
            },
            {
              lat: 48.84219491,
              lng: 2.58568646,
            },
            {
              lat: 48.84216858,
              lng: 2.58561445,
            },
            {
              lat: 48.84224034,
              lng: 2.58555938,
            },
            {
              lat: 48.84222293,
              lng: 2.58520663,
            },
            {
              lat: 48.84219927,
              lng: 2.58512779,
            },
            {
              lat: 48.84221902,
              lng: 2.58511673,
            },
            {
              lat: 48.8422133,
              lng: 2.58502549,
            },
            {
              lat: 48.84216575,
              lng: 2.58505857,
            },
            {
              lat: 48.84215845,
              lng: 2.58502729,
            },
            {
              lat: 48.84198078,
              lng: 2.58514179,
            },
            {
              lat: 48.84199243,
              lng: 2.58539104,
            },
            {
              lat: 48.84203672,
              lng: 2.58545336,
            },
            {
              lat: 48.84202601,
              lng: 2.58547797,
            },
            {
              lat: 48.84201358,
              lng: 2.5855244,
            },
            {
              lat: 48.84200919,
              lng: 2.58555577,
            },
            {
              lat: 48.8420066,
              lng: 2.58558713,
            },
            {
              lat: 48.84200581,
              lng: 2.58561847,
            },
            {
              lat: 48.84200587,
              lng: 2.58563619,
            },
            {
              lat: 48.84201137,
              lng: 2.58566612,
            },
            {
              lat: 48.8420214,
              lng: 2.58570419,
            },
            {
              lat: 48.8420314,
              lng: 2.58573545,
            },
            {
              lat: 48.84205205,
              lng: 2.58572438,
            },
            {
              lat: 48.84204765,
              lng: 2.58575303,
            },
            {
              lat: 48.84206849,
              lng: 2.58579783,
            },
            {
              lat: 48.842091,
              lng: 2.58580719,
            },
          ]),
        ],
      },
      {
        id: 'z_16',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.45,
          ExteriorWall_window_share_NE: 0.45,
          ExteriorWall_window_share_NW: 0.45,
          ExteriorWall_window_share_S: 0.45,
          ExteriorWall_window_share_SE: 0.45,
          ExteriorWall_window_share_SW: 0.45,
          ExteriorWall_window_share_E: 0.45,
          ExteriorWall_window_share_W: 0.45,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 97.5,
          building_id: 'b_21',
          building_name: "École d'architecture de la ville",
          infiltration_rate: 0.18,
          name: 'z_16',
          performance: 'UNDEFINED',
          usage_zone_code: 'B15',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: null,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84113755,
              lng: 2.59151682,
            },
            {
              lat: 48.840902,
              lng: 2.59154047,
            },
            {
              lat: 48.84092382,
              lng: 2.59213707,
            },
            {
              lat: 48.84095169,
              lng: 2.59213549,
            },
            {
              lat: 48.84095584,
              lng: 2.5922976,
            },
            {
              lat: 48.84106014,
              lng: 2.59228997,
            },
            {
              lat: 48.8411015,
              lng: 2.59228556,
            },
            {
              lat: 48.84124625,
              lng: 2.59227352,
            },
            {
              lat: 48.84130109,
              lng: 2.59226901,
            },
            {
              lat: 48.84136852,
              lng: 2.59226303,
            },
            {
              lat: 48.84136459,
              lng: 2.59216496,
            },
            {
              lat: 48.84143381,
              lng: 2.59215624,
            },
            {
              lat: 48.8414241,
              lng: 2.59194513,
            },
            {
              lat: 48.84135577,
              lng: 2.59195248,
            },
            {
              lat: 48.84135095,
              lng: 2.59185714,
            },
            {
              lat: 48.84142018,
              lng: 2.59184979,
            },
            {
              lat: 48.84141136,
              lng: 2.59163867,
            },
            {
              lat: 48.84134214,
              lng: 2.59164738,
            },
            {
              lat: 48.84133912,
              lng: 2.59155203,
            },
            {
              lat: 48.84135621,
              lng: 2.5915519,
            },
            {
              lat: 48.84135099,
              lng: 2.59134211,
            },
            {
              lat: 48.84116129,
              lng: 2.59135995,
            },
            {
              lat: 48.84116542,
              lng: 2.59151524,
            },
            {
              lat: 48.84113755,
              lng: 2.59151682,
            },
          ]),
        ],
      },
      {
        id: 'z_17',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.1,
          ExteriorWall_window_share_NE: 0.1,
          ExteriorWall_window_share_NW: 0.1,
          ExteriorWall_window_share_S: 0.1,
          ExteriorWall_window_share_SE: 0.1,
          ExteriorWall_window_share_SW: 0.1,
          ExteriorWall_window_share_E: 0.1,
          ExteriorWall_window_share_W: 0.1,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 95.6,
          building_id: 'b_22',
          building_name: 'Hôtel Ibis',
          infiltration_rate: 0.38,
          name: 'z_17',
          performance: '1984-1996',
          usage_zone_code: 'B23',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1989.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84260863,
              lng: 2.58465582,
            },
            {
              lat: 48.84253042,
              lng: 2.58466461,
            },
            {
              lat: 48.84249806,
              lng: 2.58467032,
            },
            {
              lat: 48.84250009,
              lng: 2.58473571,
            },
            {
              lat: 48.84252886,
              lng: 2.58473412,
            },
            {
              lat: 48.84253088,
              lng: 2.58479814,
            },
            {
              lat: 48.84252459,
              lng: 2.58479955,
            },
            {
              lat: 48.84253057,
              lng: 2.58496573,
            },
            {
              lat: 48.84247392,
              lng: 2.58497027,
            },
            {
              lat: 48.84247865,
              lng: 2.58503564,
            },
            {
              lat: 48.84248674,
              lng: 2.58503557,
            },
            {
              lat: 48.84248771,
              lng: 2.58505464,
            },
            {
              lat: 48.84245355,
              lng: 2.58506036,
            },
            {
              lat: 48.84245921,
              lng: 2.58513662,
            },
            {
              lat: 48.84246909,
              lng: 2.58513382,
            },
            {
              lat: 48.84247005,
              lng: 2.58515152,
            },
            {
              lat: 48.8424368,
              lng: 2.58515724,
            },
            {
              lat: 48.84244068,
              lng: 2.58523896,
            },
            {
              lat: 48.84251169,
              lng: 2.58522885,
            },
            {
              lat: 48.84251544,
              lng: 2.58527243,
            },
            {
              lat: 48.84253348,
              lng: 2.58528863,
            },
            {
              lat: 48.84254786,
              lng: 2.58528579,
            },
            {
              lat: 48.84254782,
              lng: 2.58527217,
            },
            {
              lat: 48.84261254,
              lng: 2.58526484,
            },
            {
              lat: 48.84261152,
              lng: 2.58522806,
            },
            {
              lat: 48.84263759,
              lng: 2.58522649,
            },
            {
              lat: 48.84263658,
              lng: 2.5851938,
            },
            {
              lat: 48.84264826,
              lng: 2.58519098,
            },
            {
              lat: 48.84263474,
              lng: 2.5849213,
            },
            {
              lat: 48.84264103,
              lng: 2.58492125,
            },
            {
              lat: 48.84263812,
              lng: 2.5848586,
            },
            {
              lat: 48.84263092,
              lng: 2.58485866,
            },
            {
              lat: 48.84262039,
              lng: 2.58467616,
            },
            {
              lat: 48.84260961,
              lng: 2.58467761,
            },
            {
              lat: 48.84260863,
              lng: 2.58465582,
            },
          ]),
        ],
      },
      {
        id: 'z_18',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.1,
          ExteriorWall_window_share_NE: 0.1,
          ExteriorWall_window_share_NW: 0.1,
          ExteriorWall_window_share_S: 0.1,
          ExteriorWall_window_share_SE: 0.1,
          ExteriorWall_window_share_SW: 0.1,
          ExteriorWall_window_share_E: 0.1,
          ExteriorWall_window_share_W: 0.1,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 95.6,
          building_id: 'b_23',
          building_name: 'Bureaux Newton',
          infiltration_rate: 0.38,
          name: 'z_18',
          performance: '1984-1996',
          usage_zone_code: 'B05',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: 1993.0,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84250662,
              lng: 2.58196153,
            },
            {
              lat: 48.84224071,
              lng: 2.58204814,
            },
            {
              lat: 48.84226742,
              lng: 2.58222915,
            },
            {
              lat: 48.84235725,
              lng: 2.58219709,
            },
            {
              lat: 48.84239135,
              lng: 2.58243389,
            },
            {
              lat: 48.84226739,
              lng: 2.58247576,
            },
            {
              lat: 48.84229045,
              lng: 2.58264317,
            },
            {
              lat: 48.84238657,
              lng: 2.58261106,
            },
            {
              lat: 48.84240959,
              lng: 2.58276348,
            },
            {
              lat: 48.84228563,
              lng: 2.58280671,
            },
            {
              lat: 48.84231139,
              lng: 2.5829741,
            },
            {
              lat: 48.84239671,
              lng: 2.58293935,
            },
            {
              lat: 48.8424299,
              lng: 2.58317208,
            },
            {
              lat: 48.84230324,
              lng: 2.58321533,
            },
            {
              lat: 48.84232629,
              lng: 2.58337865,
            },
            {
              lat: 48.84257151,
              lng: 2.58329221,
            },
            {
              lat: 48.84250662,
              lng: 2.58196153,
            },
          ]),
        ],
      },
      {
        id: 'z_19',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.1,
          ExteriorWall_window_share_NE: 0.1,
          ExteriorWall_window_share_NW: 0.1,
          ExteriorWall_window_share_S: 0.1,
          ExteriorWall_window_share_SE: 0.1,
          ExteriorWall_window_share_SW: 0.1,
          ExteriorWall_window_share_E: 0.1,
          ExteriorWall_window_share_W: 0.1,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 92.4,
          building_id: 'b_24',
          building_name: 'Sense-city',
          infiltration_rate: 0.23,
          name: 'z_19',
          performance: 'UNDEFINED',
          usage_zone_code: 'B00',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: null,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84193675,
              lng: 2.59010989,
            },
            {
              lat: 48.84190707,
              lng: 2.59011149,
            },
            {
              lat: 48.84190899,
              lng: 2.59014417,
            },
            {
              lat: 48.84170401,
              lng: 2.59016486,
            },
            {
              lat: 48.84168772,
              lng: 2.58987341,
            },
            {
              lat: 48.84189359,
              lng: 2.58984999,
            },
            {
              lat: 48.84192596,
              lng: 2.58984837,
            },
            {
              lat: 48.84193675,
              lng: 2.59010989,
            },
          ]),
        ],
      },
      {
        id: 'z_20',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.1,
          ExteriorWall_window_share_NE: 0.1,
          ExteriorWall_window_share_NW: 0.1,
          ExteriorWall_window_share_S: 0.1,
          ExteriorWall_window_share_SE: 0.1,
          ExteriorWall_window_share_SW: 0.1,
          ExteriorWall_window_share_E: 0.1,
          ExteriorWall_window_share_W: 0.1,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 97.5,
          building_id: 'b_26',
          building_name: 'Restaurant Le Descartes',
          infiltration_rate: 0.22,
          name: 'Restaurant Le Descartes',
          performance: 'UNDEFINED',
          usage_zone_code: 'B43',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: null,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84218048,
              lng: 2.58437855,
            },
            {
              lat: 48.8421768,
              lng: 2.58435405,
            },
            {
              lat: 48.84217404,
              lng: 2.58433636,
            },
            {
              lat: 48.84215193,
              lng: 2.5841853,
            },
            {
              lat: 48.84207827,
              lng: 2.58420905,
            },
            {
              lat: 48.84196777,
              lng: 2.58424535,
            },
            {
              lat: 48.84187974,
              lng: 2.58427467,
            },
            {
              lat: 48.84188486,
              lng: 2.58445584,
            },
            {
              lat: 48.84196392,
              lng: 2.58443069,
            },
            {
              lat: 48.84196758,
              lng: 2.58444973,
            },
            {
              lat: 48.8420718,
              lng: 2.58441757,
            },
            {
              lat: 48.84206811,
              lng: 2.58439307,
            },
            {
              lat: 48.84209866,
              lng: 2.58438329,
            },
            {
              lat: 48.84210233,
              lng: 2.58440506,
            },
            {
              lat: 48.84218048,
              lng: 2.58437855,
            },
          ]),
        ],
      },
      {
        id: 'z_20',
        properties: {
          ExteriorFloor_U_value: 1.0,
          ExteriorFloor_inertia: 'mid',
          ExteriorFloor_insulation: 'indoor',
          ExteriorRoof_U_value: 1.0,
          ExteriorRoof_inertia: 'mid',
          ExteriorRoof_insulation: 'indoor',
          ExteriorRoof_window_U_value: 2.0,
          ExteriorRoof_window_solar_absorption: 0.15,
          ExteriorRoof_window_transmission_factor: 0.7,
          ExteriorRoof_window_type: 'double_glazing',
          ExteriorRoof_window_share: 0.0,
          ExteriorWall_U_value: 1.0,
          ExteriorWall_inertia: 'mid',
          ExteriorWall_insulation: 'indoor',
          ExteriorWall_window_share_N: 0.1,
          ExteriorWall_window_share_NE: 0.1,
          ExteriorWall_window_share_NW: 0.1,
          ExteriorWall_window_share_S: 0.1,
          ExteriorWall_window_share_SE: 0.1,
          ExteriorWall_window_share_SW: 0.1,
          ExteriorWall_window_share_E: 0.1,
          ExteriorWall_window_share_W: 0.1,
          ExteriorWall_window_U_value: 2.0,
          ExteriorWall_window_solar_absorption: 0.15,
          ExteriorWall_window_transmission_factor: 0.7,
          ExteriorWall_window_type: 'double_glazing',
          altitude: 100.4,
          building_id: 'b_26',
          building_name: 'Restaurant Le Descartes',
          infiltration_rate: 0.22,
          name: 'Logements étudiants',
          performance: 'UNDEFINED',
          usage_zone_code: 'B25',
          ventilation_system: 'ZoneSingleFlowVentilation',
          year: null,
          ...heightsAndAreas,
          blind_control_mode: 1,
          closed_blind_ratio: 0.1,
          closing_deltaT: 3.0,
          open_blind_ratio: 1.0,
          temperature_blind_closed: 35.0,
          ...defaultProfiles,
        },
        geometry: [
          toRing([
            {
              lat: 48.84218048,
              lng: 2.58437855,
            },
            {
              lat: 48.8421768,
              lng: 2.58435405,
            },
            {
              lat: 48.84217404,
              lng: 2.58433636,
            },
            {
              lat: 48.84215193,
              lng: 2.5841853,
            },
            {
              lat: 48.84207827,
              lng: 2.58420905,
            },
            {
              lat: 48.84196777,
              lng: 2.58424535,
            },
            {
              lat: 48.84187974,
              lng: 2.58427467,
            },
            {
              lat: 48.84188486,
              lng: 2.58445584,
            },
            {
              lat: 48.84196392,
              lng: 2.58443069,
            },
            {
              lat: 48.84196758,
              lng: 2.58444973,
            },
            {
              lat: 48.8420718,
              lng: 2.58441757,
            },
            {
              lat: 48.84206811,
              lng: 2.58439307,
            },
            {
              lat: 48.84209866,
              lng: 2.58438329,
            },
            {
              lat: 48.84210233,
              lng: 2.58440506,
            },
            {
              lat: 48.84218048,
              lng: 2.58437855,
            },
          ]),
        ],
      },
    ];
  }

  async persistStudyThermalZones(
    ignoredStudyId: number,
    ignoredThermalZones: ThermalZone[],
  ): Promise<void> {
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
  }
}
