import { type Scenario, ScenarioCategoryEnum, ScenarioUnitEnum } from '../api-client';
import { usageCodeOptions } from '../thermal-zone/thermal-zone.interface';
import type { ScenarioApi, Scenarios } from './scenario.interface';

export class ScenarioApiMock implements ScenarioApi {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  async getScenarios(withDefault: boolean, projectId?: number): Promise<Scenarios> {
    const defaultScenarios: Scenario[] = [];
    const userScenarios: Scenario[] = [];
    const projectScenarios: Scenario[] = [];

    const scenarioTemplates = [
      {
        category: ScenarioCategoryEnum.WaterFlow,
        source: 'RT2012 - Industrie - 8h à 18h',
        unit: ScenarioUnitEnum.LHM2,
        default: true,
        display_property: 'relative',
        value_max: 0.001190476190476191,
      },
      {
        category: ScenarioCategoryEnum.CoolingSetPoint,
        source: 'RT2012 - Enseignement - Université',
        unit: ScenarioUnitEnum.C,
        default: true,
        display_property: 'absolute',
        value_max: 1,
      },
      {
        category: ScenarioCategoryEnum.HeatingSetPoint,
        source: 'RT2012 - Enseignement secondaire (partie jour)',
        unit: ScenarioUnitEnum.C,
        default: true,
        display_property: 'absolute',
        value_max: 1,
      },
      {
        category: ScenarioCategoryEnum.InternalGainMetabolism,
        source: 'RT2012 - Industrie - 8h à 18h',
        unit: ScenarioUnitEnum.WM2,
        default: true,
        display_property: 'relative',
        value_max: 3.6,
      },
      {
        category: ScenarioCategoryEnum.ElectricLoad,
        source: 'RT2012 - Enseignement secondaire (partie jour)',
        unit: ScenarioUnitEnum.WM2,
        default: true,
        display_property: 'relative',
        value_max: 5.140000000000001,
      },
      {
        category: ScenarioCategoryEnum.AirChangeRateSetPoint,
        source: 'RT2012 - Enseignement - Université',
        unit: ScenarioUnitEnum.VolM3,
        default: true,
        display_property: 'relative',
        value_max: 1,
      },
    ];

    scenarioTemplates.forEach((template) => {
      usageCodeOptions.forEach((usageCode) => {
        defaultScenarios.push({
          ...template,
          _key: `${template.category} ${usageCode}`,
          code_typology: usageCode,
          scenario_name: `${template.category} ${usageCode}`,
        });
      });
    });

    return { default: defaultScenarios, user: userScenarios, project: projectScenarios };
  }
}
